import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import en from "./en.json";
import bg from "./bg.json";

export const messages = { en, bg };

export function Translate({ id, values }) {
  return <FormattedMessage id={id} values={values} defaultMessage={en[id]} />;
}

export function useText() {
  const intl = useIntl();
  return (message) =>
    intl.formatMessage({
      id: message,
      defaultMessage: en[message],
    });
}

export function useSlug() {
  const t = useText();
  return (slugIds) => `/${slugIds.map((id) => t(id)).join("/")}`;
}
