import React from "react";
import { useHistory } from "react-router-dom";
import NavBar, { NavItem, NavLink } from "../../lib/NavBar";
import { Translate, useSlug } from "../../translation";

export default function Header({ language, setLanguage }) {
  const slug = useSlug();
  const history = useHistory();

  const handleChangeLanguage = () => {
    setLanguage(language === "en" ? "bg" : "en");
    history.replace("/");
  };

  return (
    <NavBar>
      <ul className="navbar-nav mx-auto">
        <NavItem>
          <NavLink exact to="/">
            <Translate id="navbar.home" />
            <span className="sr-only">(current)</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={slug(["slug.about"])}>
            <Translate id="navbar.about" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={slug(["slug.sportCalendar"])}>
            <Translate id="navbar.sportCalendar" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={slug(["slug.nationalChampionships"])}>
            <span className="d-lg-none">
              <Translate id="navbar.nationalChampionships" />
            </span>
            <span className="d-none d-lg-block">
              <Translate id="navbar.nationalChampionships.abr" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={slug(["slug.winterGames"])}>
            <span className="d-lg-none">
              <Translate id="navbar.winterGames" />
            </span>
            <span className="d-none d-lg-block">
              <Translate id="navbar.winterGames.abr" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={slug(["slug.nationalUniversiade"])}>
            <Translate id="navbar.nationalUniversiade" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={slug(["slug.summerGames"])}>
            <span className="d-lg-none">
              <Translate id="navbar.summerGames" />
            </span>
            <span className="d-none d-lg-block">
              <Translate id="navbar.summerGames.abr" />
            </span>
          </NavLink>
        </NavItem>
      </ul>
      <button onClick={handleChangeLanguage}>{language}</button>
    </NavBar>
  );
}
