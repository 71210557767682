import React from "react";
import { useIntl } from "react-intl";

export default function About() {
  const intl = useIntl();

  return (
    <>
      <div>{intl.formatMessage({ id: "navbar.about" })}</div>
    </>
  );
}
