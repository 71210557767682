import React from "react";
import { useIntl } from "react-intl";

export default function Home() {
  const intl = useIntl();

  return (
    <>
      <div>{intl.formatMessage({ id: "navbar.home" })}</div>
    </>
  );
}
