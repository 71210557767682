import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../../pages/Home";
import About from "../../pages/About";
import SportCalendar from "../../pages/SportCalendar";
import NationalChampionships from "../../pages/NationalChampionships";
import WinterGames from "../../pages/WinterGames";
import NationalUniversiade from "../../pages/NationalUniversiade";
import SummerGames from "../../pages/SummerGames";
import { useSlug } from "../../translation";

export default function Main() {
  const slug = useSlug();

  return (
    <main>
      <Switch>
        <Route path={slug(["slug.about"])}>
          <About />
        </Route>
        <Route path={slug(["slug.sportCalendar"])}>
          <SportCalendar />
        </Route>
        <Route path={slug(["slug.nationalChampionships"])}>
          <NationalChampionships />
        </Route>
        <Route path={slug(["slug.winterGames"])}>
          <WinterGames />
        </Route>
        <Route path={slug(["slug.nationalUniversiade"])}>
          <NationalUniversiade />
        </Route>
        <Route path={slug(["slug.summerGames"])}>
          <SummerGames />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </main>
  );
}
