import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import "./styles.scss";

export function NavItem({ children }) {
  return <li className="nav-item">{children}</li>;
}

export function NavLink({ to, children, exact }) {
  return (
    <RouterNavLink className="nav-link text-uppercase" to={to} exact={exact}>
      {children}
    </RouterNavLink>
  );
}

export default function NavBar({ children }) {
  const [show, setShow] = useState(false);

  const handleToggleExpand = () => {
    setShow(!show);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <button
        onClick={handleToggleExpand}
        className="navbar-toggler"
        type="button"
        aria-expanded={show}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={`navbar-collapse collapse${show ? " show" : ""}`}>
        {children}
      </div>
    </nav>
  );
}
