import React from "react";
import { useIntl } from "react-intl";

export default function WinterGames() {
  const intl = useIntl();

  return (
    <>
      <div>{intl.formatMessage({ id: "navbar.winterGames" })}</div>
    </>
  );
}
