import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import Main from "../Main";
import Header from "../Header";
import { messages } from "../../translation";

function App() {
  // // navigator.language = "en-US"
  // const language = navigator.language.split(/[-_]/)[0];
  const [language, setLanguage] = useState("bg");

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Header language={language} setLanguage={setLanguage} />
      <Main />
    </IntlProvider>
  );
}

export default App;
